import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';
import { ActionUpdateTitle } from './redux/reducers';
import { Onboard } from './Onboard';

const TITLE = "AppGecko"

const dev = window.location.port === "3000" ? true : false;

const client_id = "4b4f8htlj5229p9ctqkb6e996f";
const redirect_uri = dev ? "http://localhost:3000" : "https://int.appgecko.io/app";
const authorize_uri = `https://appgeckoio.auth.eu-west-1.amazoncognito.com/oauth2/authorize?response_type=token&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid+email+profile`


const systems = [
  {
    name: "Internal Business Systems",
    href: "https://int.appgecko.io/app",
    description: "App Platform."
  }
];

const getNewUserToken = async () => {
  setTimeout(() => {
    console.log("redirecting in 2");
    window.location.assign(authorize_uri);

  }, 2000);
  return null;
}
const verify_id_token = (token) => {
  if (token === null) return null;
  if (token === undefined) return null;

  let token_decoded = jwt.decode(token);
  if (token_decoded === null) return null;

  console.log(token_decoded);

  let tokenExpiresAt = token_decoded.exp
  let currentSecond = Math.floor(Date.now() / 1000);

  if (token_decoded.aud !== client_id) {
    console.log("Token audience does not match this client");
    return null;
  }

  if (tokenExpiresAt > currentSecond) {
    console.log("Token time is valid")
    return token_decoded;
  } else {
    console.log("token expired");
    return null;
  }
}

const getValidUser = async () => {
  return new Promise(async (resolve, reject) => {

    let params = new URLSearchParams(window.location.hash.replace('#', ''));
    let id_token;
    let id_token_url = params.get('id_token');
    if (id_token_url !== null && id_token_url !== undefined && id_token_url !== "") {
      id_token = id_token_url;
      localStorage.setItem('id_token', id_token);
      console.log("Saving new id_token & refreshing page");
      window.location.assign(redirect_uri);
      resolve({});
      return;
    } else {

      let id_token_stored = localStorage.getItem('id_token');
      console.log(id_token_stored);
      if (id_token_stored === undefined || id_token_stored === null) {
        console.log("user is not logged in and has no token");
        // id_token = await getNewUserToken();
        resolve({});
        return;
      } else {
        id_token = id_token_stored;
      }
    }


    /// Check id_token is valid
    let id_token_decoded = verify_id_token(id_token);
    if (id_token_decoded === null) {
      console.log("id_token is invalid, need to get a new one");
      id_token = await getNewUserToken();
      resolve({});
    } else {
      console.log("Token is valid, can load page / redirect user");
      resolve(id_token_decoded);
    }
  })
}

function App({ title, updateTitle }) {
  const [user, setUser] = useState({});

  useEffect(() => {
    /// Check if the user is logged in
    console.log("Use effect");
    // window.document.title=TITLE;
    updateTitle(TITLE);
    let doEffect = async () => {
      let newUser = await getValidUser();
      if (_loggedIn(newUser)) {
        updateTitle(TITLE + " - Internal systems")
      }
      setUser(newUser);
    }
    doEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = () => {
    window.location.assign(authorize_uri);
  }

  const PreLoginText = "Unauthorised use of this website is prohibited.";

  const loggedIn = () => _loggedIn(user);
  const _loggedIn = (_user) => _user !== {} && _user !== null && _user !== undefined && Object.keys(_user).length > 0;
  return (
    <Router>
      <Box m="auto" maxWidth={400} textAlign={loggedIn() ? "left" : "center"}>
        <Box m={2}>
          <Grid container spacing={2}>
            <Route path="/onboard/:email">
              <Grid item xs={12}>
                <Onboard />
              </Grid>
            </Route>
            <Route exact={true} path="/">
              <Grid item xs={12}>
                <Typography variant="h5">{title}</Typography>
                <Typography variant='body2'>{!loggedIn() ? "" : `Logged in as ${user.name}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                {loggedIn() && <ul>
                  {systems.map((system) => <li key={system.href}><Link target="_blank" href={system.href} >{system.name}</Link>{system.description === null ? "" : ` - ${system.description}`}</li>)}
                </ul>}
                {!loggedIn() && <Button variant="contained" onClick={handleLogin}>Log in</Button>}
              </Grid>
            </Route>
            <Route path="/">
              <Grid item xs={12}>
                <Typography variant='caption'>{PreLoginText}</Typography>
              </Grid>
            </Route>
          </Grid>
        </Box>
      </Box>
    </Router>
  );
}

export default connect((state) => ({
  title: state.pages.title
}), (dispatch) => ({
  updateTitle: (newTitle) => dispatch(ActionUpdateTitle(newTitle))
}))(App);
