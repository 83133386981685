import { combineReducers } from "redux";

export const ActionUpdateTitle = (newTitle) => ({
    type:'Set page title',
    newTitle
})

function pages(state = {
    '/User':'Users',
    title:""
}, action) {
    switch (action.type){
        case "add page":{
            return Object.assign({},state,{
                [action.page]:action.pageName
            })
        }
        case "Set page title":{
            window.document.title = action.newTitle;
            return Object.assign({},state,{title:action.newTitle})
        }
        default: return state;
    }
}
  

export default combineReducers({ pages});
