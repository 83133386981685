import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useParams } from 'react-router';

export const Onboard = () => {

    let {email} = useParams();
    return (
        <Box textAlign="left" p={3} borderRadius={15} style={({backgroundColor:"#eee"})}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Hello {email}, Welcome to AppGecko!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Please choose a username to use throughout our internal systems.</Typography>
          </Grid>
          <Grid item xs={12}>
              <Box m={2}></Box>
          </Grid>
          <Grid item xs={8}>
            <TextField fullWidth={true} label="Preferred username" />
          </Grid>
          <Grid item xs={4} style={({display:"flex"})}>
            <Box alignSelf="end">
              <Typography variant="body2">@appgecko.io</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
              <Typography variant="caption">This username cannot be changed once you have chosen it.</Typography>
          </Grid>
          <Grid item xs={12}>
              <Box m={1} textAlign="center">
                  <Button variant="container">Set username</Button>
              </Box>
          </Grid>
        </Grid>
      </Box>

    )
}